import React from "react";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import { SunhubIcon } from "../../admin/pages/dasboard/components/icons";

const LoginModal = ({ loginModalVisible, setIsLoginModalVisible, userData }) => {
	const history = useHistory();
	const isUserSeller = userData.userData.isLoggedIn && userData.userData.data.role && userData.userData.data.role.includes("seller");
	/**
	 * For Later usage
			const isUserCustomer = userData.userData.isLoggedIn && userData.userData.data.role && userData.userData.data.role.includes("customer")
		*/

	const goToSellerCentral = () => {
		if (isUserSeller) {
			setIsLoginModalVisible(false);
			history.push(`${process.env.PUBLIC_URL}/my-sunhub/seller-overview/sell-an-item`);
		} else {
			setIsLoginModalVisible(false);
			history.push(`${process.env.PUBLIC_URL}/register-business/seller`);
		}
	};
	const goToTraderPlatform = () => {
		if (isUserSeller) {
			setIsLoginModalVisible(false);
			history.push(`${process.env.PUBLIC_URL}/trader/trader-deals`);
		} else {
			setIsLoginModalVisible(false);
			history.push(`${process.env.PUBLIC_URL}/trader/how-to-sell`);
			// history.push(`${process.env.PUBLIC_URL}/solar-trader-platform`);
		}
	};
	return (
		<>
			<Modal
				className="select-seller-modal"
				visible={loginModalVisible}
				centered
				onOk={() => setIsLoginModalVisible(false)}
				onCancel={() => setIsLoginModalVisible(false)}
				footer={null}
				width={1300}
				closable={true}>
				<div className="login-wrapper">
					<div className="row">
						<div className="col-lg-6">
							<div className="row h-100 hover-on">
								<div className="col-lg-4">
									<div className="h-100 bg-light w-100">
										<img
											src={`${process.env.PUBLIC_URL}/assets/images/revamp/sell-menu/bulk-sale.jpg`}
											alt="Bulk Sale"
											className="w-100 h-100 rounded"
										/>
										<img
											src={`${process.env.PUBLIC_URL}/assets/images/revamp/sell-menu/bulk-sale-hover.jpg`}
											alt="Bulk Sale Hover"
											className="w-100 h-100 show-on-hover rounded"
										/>
									</div>
								</div>
								<div className="col-lg-8">
									<div className="card">
										<div className="card-body">
											<h5 className="text-primary fw-semibold mb-5">Negotiable - Price and Terms</h5>
											<ul className="trader-feature-list">
												<li>
													<span className="sunhub-icon">
														<SunhubIcon />
													</span>
													All Solar Equipment Categories
												</li>
												<ul>
													<li>Solar Panels, Batteries, Inverters & Components</li>
												</ul>
												<li>
													<span className="sunhub-icon">
														<SunhubIcon />
													</span>
													Minimum Order Quantity Requirements
												</li>
												<li>
													<span className="sunhub-icon">
														<SunhubIcon />
													</span>
													Selling Unit - PPW or Quantity
												</li>
												<li>
													<span className="sunhub-icon">
														<SunhubIcon />
													</span>
													Inventory Available On Hand or Pre-Production
												</li>
												<li>
													<span className="sunhub-icon">
														<SunhubIcon />
													</span>
													Accept Offers
												</li>
												<li>
													<span className="sunhub-icon">
														<SunhubIcon />
													</span>
													Negotiable Online
												</li>
												<ul>
													<li>Price</li>
													<li>Logistics/Incoterms</li>
													<li>Payment and Delivery Terms</li>
													<li>Add-on Services</li>
												</ul>
												<li>
													<span className="sunhub-icon">
														<SunhubIcon />
													</span>
													Contract based Checkout Process
												</li>
												<li>
													<span className="sunhub-icon">
														<SunhubIcon />
													</span>
													Offer Negotiation with Seller Anonymity
												</li>
												<li>
													<span className="sunhub-icon">
														<SunhubIcon />
													</span>
													Sunhub Moderated and Assisted
												</li>
												<li>
													<span className="sunhub-icon">
														<SunhubIcon />
													</span>
													Sunhub Marketing Offerings Available
												</li>
											</ul>
										</div>

										<button
											onClick={goToTraderPlatform}
											className="btn btn-outline-primary-2 btn-block btn-lg">
											Go to Solar Trader Platform
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="row h-100 hover-on">
								<div className="col-lg-4">
									<div className="h-100 bg-light w-100">
										<img
											src={`${process.env.PUBLIC_URL}/assets/images/revamp/sell-menu/unit-sale.jpg`}
											alt="Unit Sale"
											className="w-100 h-100 rounded"
										/>
										<img
											src={`${process.env.PUBLIC_URL}/assets/images/revamp/sell-menu/unit-sale-hover.jpg`}
											alt="Unit Sale Hover"
											className="w-100 h-100 show-on-hover rounded"
										/>
									</div>
								</div>
								<div className="col-lg-8">
									<div className="card">
										<div className="card-body">
											<h5 className="text-primary fw-semibold mb-5">Buy Now - Fixed Price and Terms</h5>
											<ul className="trader-feature-list">
												<li>
													<span className="sunhub-icon">
														<SunhubIcon />
													</span>
													All Solar Equipment Categories
												</li>
												<ul>
													<li>Solar Panels, Batteries, Inverters & Components</li>
												</ul>
												<li>
													<span className="sunhub-icon">
														<SunhubIcon />
													</span>
													No Minimum Order Quantity Requirements
												</li>
												<li>
													<span className="sunhub-icon">
														<SunhubIcon />
													</span>
													Selling Unit - Quantity
												</li>
												<li>
													<span className="sunhub-icon">
														<SunhubIcon />
													</span>
													Inventory Available on Hand
												</li>
												<li>
													<span className="sunhub-icon">
														<SunhubIcon />
													</span>
													Fixed Price
												</li>
												<li>
													<span className="sunhub-icon">
														<SunhubIcon />
													</span>
													Non Negotiable Terms
												</li>
												<ul>
													<li>Fixed Price</li>
													<li>Shipping Included or Local Pickup</li>
													<li>Upfront Payment</li>
													<li>Support via Sunhub Customer Service</li>
												</ul>
												<li>
													<span className="sunhub-icon">
														<SunhubIcon />
													</span>
													Cart based Checkout Process
												</li>
												<li>
													<span className="sunhub-icon">
														<SunhubIcon />
													</span>
													Named Supplier
												</li>
												<li>
													<span className="sunhub-icon">
														<SunhubIcon />
													</span>
													Online E-com Transaction
												</li>
												<li>
													<span className="sunhub-icon">
														<SunhubIcon />
													</span>
													Sunhub Marketing Offerings Available
												</li>
											</ul>
										</div>
										<button
											onClick={goToSellerCentral}
											className="btn btn-outline-primary-2 btn-block btn-lg">
											Go To Seller Central
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <div className="row">
						<div className="col-12">
							<div className="global-center-heading pt-0">
								<h1 className="title mb-0">If you are Selling</h1>
							</div>
						</div>
						<div className="col-md-6 mb-2 mb-md-0">
							<div className="login-container flex-vertical">
								<div className="">
									<div className="icon-block">
										<img
											className="user-icon"
											src={`${process.env.PUBLIC_URL}/assets/images/broker/login/user-icon.svg`}
											alt="Icon On Hover"
										/>
										<span className="show-on-hover">
											<img
												className="user-icon d-none"
												src={`${process.env.PUBLIC_URL}/assets/images/broker/login/icon-on-hover.svg`}
												alt="Icon On Hover"
											/>
										</span>
									</div>
									<ul className="offered-services-lists list-has-icons">
										<li>
											<span className="hoverable-icon">
												<img
													src={`${process.env.PUBLIC_URL}/assets/images/broker/login/sunhub-icon.svg`}
													alt="sunhub icon"
												/>
											</span>
											Solar Panels PPW
										</li>
										<li>
											<span className="hoverable-icon">
												<img
													src={`${process.env.PUBLIC_URL}/assets/images/broker/login/sunhub-icon.svg`}
													alt="sunhub icon"
												/>
											</span>
											Batteries & Inverters
										</li>
										<li className="has-sub-items">
											<span className="hoverable-icon">
												<img
													src={`${process.env.PUBLIC_URL}/assets/images/broker/login/sunhub-icon.svg`}
													alt="sunhub icon"
												/>
											</span>
											Have Negotiable Contract Terms
											<ul>
												<li>Incoterms</li>
												<li>Payment</li>
												<li>Delivery</li>
											</ul>
										</li>
										<li>
											<span className="hoverable-icon">
												<img
													src={`${process.env.PUBLIC_URL}/assets/images/broker/login/sunhub-icon.svg`}
													alt="sunhub icon"
												/>
											</span>
											Want To Receive Bids
										</li>
									</ul>
								</div>
								<button
									onClick={goToTraderPlatform}
									// to="/broker/create-deal"
									className="btn btn-dark btn-lg py-3 btn-block">
									Go to Solar Trader Platform
								</button>
							</div>
						</div>
						<div className="col-md-6">
							<div className="login-container flex-vertical">
								<div className="">
									<div className="icon-block">
										<img
											className="user-with-money"
											src={`${process.env.PUBLIC_URL}/assets/images/broker/login/user-money.svg`}
											alt="Icon On Hover"
										/>
										<span className="show-on-hover">
											<img
												className="user-with-money d-none"
												src={`${process.env.PUBLIC_URL}/assets/images/broker/login/icon-on-hover-1.svg`}
												alt="Icon On Hover"
											/>
										</span>
									</div>
									<ul className="offered-services-lists list-has-icons">
										<li>
											<span className="hoverable-icon">
												<img
													src={`${process.env.PUBLIC_URL}/assets/images/broker/login/sunhub-icon.svg`}
													alt="sunhub icon"
												/>
											</span>
											Everything else
										</li>
										<li>
											<span className="hoverable-icon">
												<img
													src={`${process.env.PUBLIC_URL}/assets/images/broker/login/sunhub-icon.svg`}
													alt="sunhub icon"
												/>
											</span>
											Price per unit
										</li>
										<li>
											<span className="hoverable-icon">
												<img
													src={`${process.env.PUBLIC_URL}/assets/images/broker/login/sunhub-icon.svg`}
													alt="sunhub icon"
												/>
											</span>
											Fixed Terms
										</li>
										<li>
											<span className="hoverable-icon">
												<img
													src={`${process.env.PUBLIC_URL}/assets/images/broker/login/sunhub-icon.svg`}
													alt="sunhub icon"
												/>
											</span>
											Fixed Price
										</li>
									</ul>
								</div>

								<button
									onClick={goToSellerCentral}
									className="btn btn-dark btn-lg py-3 btn-block">
									Go to Seller Central
								</button>
							</div>
						</div>
					</div> */}
				</div>
			</Modal>
		</>
	);
};

export default LoginModal;
